var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"tw-flex tw-flex-col tw-p-0 lg:tw-max-w-screen-2xl max-lg:!tw-max-w-full max-lg:tw-px-2",class:[
    {
      'lg:tw-bg-transparent tw-bg-primary': _vm.isAccountMenuMobile,
      'md:tw-bg-transparent tw-bg-primary': _vm.isIndexRoute
    }
  ]},[_c('div',{ref:"alertsContainerRef",staticClass:"tw-flex tw-flex-col [&>*:first-child]:tw-mt-2 md:tw-px-2 md:tw-px-2",class:{
      '[&>*:last-child]:lg:tw-mb-[-3rem] [&>*:last-child]:lg:tw-z-[1]': _vm.isAccountRoute
    }},[_c('BackendFlashMessage'),_vm._v(" "),_c('client-only',[(_vm.clientFlashMessage)?_c('ClientFlashMessage',{attrs:{"client-flash-message":_vm.clientFlashMessage}}):_vm._e()],1),_vm._v(" "),(_vm.userIsManager)?[(_vm.showSubscriptionExpiryAlert)?_c('SubscriptionExpiryAlert',{attrs:{"expires-soon":_vm.subscriptionExpiresSoon}}):(!_vm.hideCreditsGlobalAlertCookie && _vm.showOutOfCreditsMessage)?_c('CreditsDepletedAlert'):(!_vm.hideCreditsGlobalAlertCookie && _vm.showLowCreditsMessage)?_c('CreditsLowAlert'):(_vm.showSaleRequestsCreditsAlert)?_c('SaleRequestsCreditsAlert'):_vm._e()]:(_vm.userIsGuest)?[(!_vm.hideRelatedGuestsAlertCookie && _vm.showRelatedGuestsAlert)?_c('RelatedGuestsAlert'):_vm._e()]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }