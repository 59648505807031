


















import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    isMounted: {
      type: Boolean,
      required: false,
      default: false
    },
    sm: {
      type: Boolean,
      required: false,
      default: false
    },
    lg: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
