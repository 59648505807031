import { useSnackbar } from '~/compositions/snackbar'
import { useLogger } from '~/compositions/logger'
import { useI18n } from '~/compositions/i18n'
import { AxiosError } from 'axios'
import { isCancelError, joinErrors } from '~/utils/http'

export function useRequestError() {
  const snackbar = useSnackbar()
  const logger = useLogger()
  const { t } = useI18n()

  return function handleRequestError(error: unknown) {
    if (isCancelError(error as AxiosError)) {
      return
    }

    const response = (error as AxiosError).response
    const defaultErrorMessage = t('an error occurred please try again later')

    if (!response) {
      logger.captureError(error as AxiosError)
      snackbar.error(defaultErrorMessage)
      return
    }

    const { data } = response

    const errorMessage =
      data?.error ?? data?.message ?? joinErrors(data) ?? defaultErrorMessage
    snackbar.error(errorMessage)
  }
}
