import { AxiosInstance, AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import { Verify } from '~/models/user/types'
import { invalidBodyError } from '../errors'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class VerifyService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async sendPIN(pin: number): Promise<any> {
    const response: AxiosResponse = await this.http.put(
      '/api/users/verification/telephone/',
      { pin }
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data)
  }

  async sendPhoneNumber(): Promise<{
    expirationSeconds: number
    message: string
  }> {
    const response: AxiosResponse = await this.http.post(
      '/api/users/verification/telephone/'
    )
    const { data: body } = toCamelCase(response)
    if (!body) {
      throw invalidBodyError(body)
    }
    return { ...body.data, message: body.message }
  }

  async fetchVerificationData(): Promise<{
    verification: {
      creditCard: object
      telephone: object
    }
  }> {
    const response: AxiosResponse = await this.http.get(
      '/api/users/verification/'
    )
    const { data: body } = response
    if (!body?.data?.verification) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data.verification)
  }

  async fetchPhoneNumber(): Promise<Verify> {
    const response: AxiosResponse = await this.http.get(
      '/api/users/verification/telephone/'
    )
    const { data: body } = response
    if (!body) {
      throw invalidBodyError(body)
    }
    return toCamelCase(body.data)
  }
}
