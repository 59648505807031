import { CIcon } from '~/icons/types'

export const ciWhatsApp: CIcon = {
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M14.865 13.403c-.454.186-.744.897-1.039 1.26-.15.186-.33.215-.563.122-1.705-.68-3.011-1.817-3.952-3.386-.16-.243-.131-.435.061-.661.284-.335.641-.715.718-1.166.17-.998-1.133-4.092-2.855-2.69-4.956 4.039 8.267 14.749 10.653 8.956.675-1.642-2.27-2.743-3.023-2.435ZM12 21.903a9.894 9.894 0 0 1-4.987-1.347 1.054 1.054 0 0 0-.806-.106l-3.281.9 1.143-2.518a1.047 1.047 0 0 0-.104-1.046A9.83 9.83 0 0 1 2.095 12c0-5.461 4.444-9.904 9.905-9.904 5.461 0 9.904 4.443 9.904 9.904 0 5.46-4.443 9.904-9.904 9.904ZM12 0C5.383 0 0 5.383 0 12c0 2.328.66 4.563 1.917 6.503L.094 22.518A1.048 1.048 0 0 0 1.048 24c.676 0 4.362-1.158 5.3-1.416A11.993 11.993 0 0 0 12 24c6.616 0 12-5.384 12-12 0-6.617-5.384-12-12-12Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  name: 'whats-app',
  type: 'brand'
}
