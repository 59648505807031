import { render, staticRenderFns } from "./AccountSpinner.vue?vue&type=template&id=37098c0f&scoped=true&"
import script from "./AccountSpinner.vue?vue&type=script&lang=ts&"
export * from "./AccountSpinner.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37098c0f",
  null
  
)

export default component.exports