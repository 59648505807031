






















































import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import { Option } from '~/models/shared/types'
import { mapGetters } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import PhoneService from '~/services/PhoneService'
import CAppTelephoneButton from '~/components/shared/configurable/button/CAppTelephoneButton.vue'
import { TelephoneSocial } from '~/constants/telephone/social'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CAppTelephoneButton,
    CCustomSelect
  },
  props: {
    codeParentheses: {
      type: Boolean,
      default: true
    },
    name: { type: String, default: undefined },
    customClass: { type: String, default: '' },
    defaultCode: { type: Number, default: 30 },
    value: { type: Object, default: null },
    telephoneInputState: {
      type: [Boolean, String, Object],
      default: null
    },
    inputClass: {
      type: [Object, Array, String],
      default() {
        return []
      }
    },
    noPortal: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    showSocial: {
      type: Boolean,
      required: false,
      default: false
    },
    countries: {
      type: Array,
      default() {
        return [
          {
            name: 'Greece',
            code: 'gr',
            telephone_code: 30,
            telephone_validator: '^(800|801|2[0-9]{2}|69[0-9])[0-9]{7}$'
          },
          {
            name: 'Cyprus',
            code: 'cy',
            telephone_code: 357,
            telephone_validator: '^[0-9]*$'
          }
        ]
      }
    }
  },
  data() {
    return {
      telephone: null,
      code: null,
      internalCode: this.defaultCode,
      viber: false,
      whatsapp: false
    }
  },
  computed: {
    ...mapGetters(USER_NS, {
      userIsAnon: 'isAnon'
    }),
    isValid() {
      let validator = false
      if (this.code && this.telephone) {
        const regex = this.getValidator(this.code)
        validator = !!this.telephone.match(regex)
      } else {
        validator = true
      }
      this.$emit('validation', validator)
      return validator
    },
    realValue() {
      if (!this.telephone || this.telephone.length === 0) {
        return {
          code: null,
          telephone: null
        }
      } else {
        const tel = {
          code: parseInt(this.code || this.internalCode) || this.defaultCode,
          telephone: this.telephone || null
        }
        if (this.showSocial) {
          return {
            ...tel,
            social: {
              viber: Boolean(this.viber),
              whatsapp: Boolean(this.whatsapp)
            }
          }
        }

        return tel
      }
    },
    countriesCodesOptions(): Option[] {
      return this.countries.map(country => {
        return {
          text: `${this.formatCode(
            country.telephone_code || country.telephoneCode
          )} ${country.name}`,
          headerText: `(+${country.telephone_code || country.telephoneCode})`,
          value: country.telephone_code || country.telephoneCode,
          key: `${country.name}-${country.telephone_code ||
            country.telephoneCode}`,
          countryId: country.countryId
        }
      })
    },
    TelephoneSocial() {
      return TelephoneSocial
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          try {
            value = JSON.parse(value)
          } catch (e) {}
        }

        value = value || null

        if (value?.countryId) {
          this.code = this.countriesCodesOptions.find(
            c => c.countryId === value.countryId
          )?.value
        } else {
          this.code =
            parseInt(value?.code || this.internalCode) || this.defaultCode
        }
        this.internalCode = this.code
        if (value?.telephone) {
          const phoneService = this.$dep(PhoneService)
          this.telephone = phoneService.removeDecimalSeparatorFromPhone(
            value.telephone
          )
        } else {
          this.telephone = null
        }
        this.viber = Boolean(value?.social?.viber)
        this.whatsapp = Boolean(value?.social?.whatsapp)
      }
    }
  },
  methods: {
    getValidator(code) {
      let validator = false
      this.countries.forEach(country => {
        if (country.telephone_code === code || country.telephoneCode === code) {
          validator =
            country.telephone_validator || country.telephoneValidator || null
        }
      })
      if (validator === false && code !== 30) {
        return this.getValidator(30)
      } else if (validator !== false) {
        return validator
      }
      return null
    },
    formatCode(code) {
      if (this.codeParentheses) {
        return `(+${code})`
      }
      return `+${code}`
    },
    onCodeChange() {
      if (this.telephone) {
        this.code = this.internalCode
        this.emitValues()
      } else {
        this.code = null // nulify code but not the internal one so that country select keeps showing user's selection
      }
    },
    emitValues() {
      this.$emit('input', this.realValue)
      this.$emit('change')
    },
    onTelephoneFocus() {
      const codeSelectRef = this.$refs.codeSelectRef
      if (codeSelectRef) {
        codeSelectRef.closeDropdown()
      }
    }
  }
})
