
















































































































































































































































import { faCreditCard, faLock } from '@fortawesome/free-solid-svg-icons'
import { VerificationStateEnum } from '~/models/user/types'
import VerifyService from '~/services/user/VerifyService'
import CPayButton from '~/components/shared/configurable/payment/CPayButton.vue'
import SuccessCheckmarkAnimation from '~/components/shared/account/payments/SuccessCheckmarkAnimation.vue'
import CCollapse from '~/components/shared/configurable/collapse/CCollapse.vue'
import ScrollService from '~/services/scroll/ScrollService'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    SuccessCheckmarkAnimation,
    CPayButton,
    CCollapse
  },
  props: {
    inPanel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      phoneOrPin: null,
      phone: null,
      verificationPhoneState: 'NOT_VERIFIED',
      code: null,
      buttonLabel: this.$i18n.t('send'),
      message: { variant: 'danger', msg: '' },
      isLoading: true,
      expirationSeconds: 0,
      countdown: null,
      icons: {
        lock: faLock,
        card: faCreditCard
      },
      inputState: null,
      inputVerificationPhoneState: null,
      isButtonsDisabled: false,
      cardMessage: {
        variant: 'info',
        msg: `
        ${this.$i18n.t(
          'by clicking the "{buttonname}" button you will be charged one euro (1€).',
          {
            buttonname: this.$i18n.t('credit card confirmation')
          }
        )}
        \n
        ${this.$i18n.t('you will automatically get 40 credits as a gift.')}
        `
      },
      isPaymentLoading: false,
      verificationCardState: VerificationStateEnum.NOT_VERIFIED
    }
  },
  computed: {
    verificationStateEnum() {
      return VerificationStateEnum
    },
    isInputEmpty(): Boolean {
      return this.phoneOrPin?.trim()?.length === 0
    },
    accountSettingsUrl() {
      return this.$router.resolve({
        name: '__account_settings'
      }).href
    },
    cancelUrl() {
      return this.inPanel
        ? this.accountSettingsUrl
        : this.$router.resolve({
            name: '__user_verify'
          }).href
    },

    hasUserVerifiedPhone(): Boolean {
      return (
        this.verificationPhoneState ===
          this.verificationStateEnum.ALREADY_VERIFIED ||
        this.verificationPhoneState === this.verificationStateEnum.VERIFIED
      )
    },
    hasUserVerifiedCard(): Boolean {
      return (
        this.verificationCardState ===
          this.verificationStateEnum.ALREADY_VERIFIED ||
        this.verificationCardState === this.verificationStateEnum.VERIFIED
      )
    }
  },
  async mounted() {
    await this.fetchVerificationData()

    if (this.phoneOrPin) {
      this.$emit('fetch-done')
    }
  },
  destroyed() {
    this.clearCountdown()
  },
  methods: {
    async fetchVerificationData() {
      const verifyService = this.$dep(VerifyService)
      try {
        const res = await Promise.all([
          verifyService.fetchPhoneNumber(),
          verifyService.fetchVerificationData()
        ])
        const [phoneVerificationData, verificationData] = res

        this.phoneOrPin = phoneVerificationData.telephone.telephone
        this.phone = phoneVerificationData.telephone.telephone
        this.verificationPhoneState = phoneVerificationData.isVerified
          ? VerificationStateEnum.ALREADY_VERIFIED
          : VerificationStateEnum.NOT_VERIFIED
        this.code = phoneVerificationData.telephone.code
        this.verificationCardState = verificationData.creditCard?.verified
          ? VerificationStateEnum.ALREADY_VERIFIED
          : VerificationStateEnum.NOT_VERIFIED
        this.$emit('fetch-done')
      } catch (error) {
        this.$logger.captureError(error)
      } finally {
        this.isLoading = false
      }
    },
    async onSubmitPhone() {
      if (this.isButtonsDisabled) {
        return
      }
      this.isLoading = true
      const verifyService = this.$dep(VerifyService)
      try {
        const res = await verifyService.sendPhoneNumber()
        this.expirationSeconds = res.expirationSeconds - 1
        this.verificationPhoneState = this.verificationStateEnum.ENTER_PIN
        this.phoneOrPin = ''
        this.startCountdown()
        this.setMessage('message', 'success', res.message)
      } catch (error) {
        if (error?.response?.status === 429) {
          this.isButtonsDisabled = true
        }
        if (error?.response?.data?.error) {
          this.setMessage('message', 'danger', error?.response?.data?.error)
        }
        this.$logger.captureError(error)
      } finally {
        this.isLoading = false
      }
    },

    async onSubmitPIN() {
      if (this.isButtonsDisabled) {
        return
      }
      this.setMessage('message', 'danger', '')
      this.isLoading = true
      const verifyService = this.$dep(VerifyService)

      try {
        await verifyService.sendPIN(this.phoneOrPin)
        this.verificationPhoneState = this.verificationStateEnum.VERIFIED
        this.phoneOrPin = ''
        this.clearCountdown(false)
        this.$emit('phone-verified')
      } catch (error) {
        if (error?.response?.data?.error) {
          this.setMessage('message', 'danger', error.response.data.error)
        }
        if (error?.response?.data?.errors) {
          const errors = error.response.data.errors
          this.setMessage('message', 'danger', Object.values(errors).join(', '))
        }
        this.inputVerificationPhoneState = false
        this.$logger.captureError(error)
      } finally {
        this.isLoading = false
      }
    },
    onSubmit() {
      if (
        this.verificationPhoneState === this.verificationStateEnum.NOT_VERIFIED
      ) {
        this.onSubmitPhone()
      } else {
        this.onSubmitPIN()
      }
    },

    startCountdown() {
      this.countdown = setInterval(() => {
        if (this.expirationSeconds - 1 <= 0) {
          this.clearCountdown()
        }
        const minutes = Math.floor(this.expirationSeconds / 60)

        let seconds: string | number = this.expirationSeconds - minutes * 60
        seconds = seconds < 10 ? `0${seconds}` : seconds

        this.expirationSeconds--
        this.buttonLabel = `${this.$i18n.t('send')} ${
          this.expirationSeconds > 0 ? `(0${minutes}:${seconds})` : ''
        }`
      }, 1000)
    },
    clearCountdown(canSetState: Boolean = true) {
      this.expirationSeconds = 0
      this.phoneOrPin = this.phone
      this.setMessage('message', 'danger', '')
      if (canSetState) {
        this.verificationPhoneState = this.verificationStateEnum.NOT_VERIFIED
      }
      this.inputVerificationPhoneState = null
      clearInterval(this.countdown)
    },

    setMessage(variable: string, variant: string, msg: string) {
      this[variable] = {
        variant,
        msg
      }
    },
    handleChangePhone() {
      const scrollService = this.$dep(ScrollService)
      scrollService.scrollToTopSmooth()
    }
  }
})
