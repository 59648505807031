

























































import { computed, defineComponent } from '~/utils/nuxt3-migration'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import TransferGuide from '~/components/car/classifieds/user/rogue/TransferGuide.vue'
import RogueClassifieds from '~/components/car/cpanel/RogueClassifieds.vue'
import GuestSettingsForm from '~/components/car/account/settings/GuestSettingsForm.vue'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  components: { GuestSettingsForm, RogueClassifieds, TransferGuide },
  setup() {
    const { state } = useNamespacedStore<UserState>(USER_NS)

    const rogueClassifiedsCount = computed(() => state.rogueClassifiedsCount)
    const rogueRequirements = computed(() => state.rogueRequirements)

    const rogueRequirementsMet = computed(() => {
      if (rogueRequirements.value) {
        return (
          rogueRequirements.value.hasTelephone &&
          rogueRequirements.value.hasAlias &&
          rogueRequirements.value.hasPostcode
        )
      }
      return false
    })

    return {
      rogueClassifiedsCount,
      rogueRequirements,
      rogueRequirementsMet
    }
  },
  i18n: defineComponentTranslations({
    no_rogues_found: {
      en: 'No other classifieds were found for transfer',
      el: 'Δεν βρέθηκαν άλλες αγγελίες για μεταφορά'
    },
    rogue_subtitle: {
      en:
        'You can transfer classifieds with the same email you posted on Car.gr before creating an account',
      el:
        'Μπορείς να μεταφέρεις αγγελίες που είχες καταχωρίσει με το ίδιο email στο Car.gr πριν φτιάξεις λογαριασμό'
    }
  })
})
