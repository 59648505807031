import { toSnakeCase } from '~/utils/snake-case'
import { inject } from 'tsyringe'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { containerScoped } from '~/decorators/dependency-container'

@containerScoped()
export default class EurobankPaymentsService {
  constructor(@inject(RequestBuilder) private requestBuilder: RequestBuilder) {}

  createPayment(data: {
    amount: number
    confirmUrl: string
    cancelUrl: string
    type: string
    description: string
  }): Promise<{ payment: any }> {
    return this.requestBuilder
      .request('POST', '/api/payments/eurobank/create/')
      .data(toSnakeCase(data))
      .send()
  }
}
