import { CIcon } from '~/icons/types'
export const ciVerified: CIcon = {
  name: 'verified',
  type: 'regular',
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M14.3 9.3 11 12.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2c.2.2.5.3.7.3.2 0 .5-.1.7-.3l4-4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0Z'
    },
    {
      d:
        'M20.7 9.7c-.3-.3-.6-.6-.7-.8-.1-.3-.1-.8-.2-1.3 0-.8-.1-1.9-.8-2.7-.7-.8-1.7-.8-2.5-.8-.4 0-.8 0-1.1-.1-.3-.1-.6-.4-1-.8C13.9 2.7 13.1 2 12 2c-1.1 0-1.8.7-2.3 1.3-.3.2-.6.5-.8.6-.3.2-.8.2-1.3.2-.8 0-1.9.1-2.7.8-.7.7-.7 1.7-.7 2.5 0 .4 0 .8-.1 1.1-.1.3-.4.6-.8 1-.6.6-1.3 1.4-1.3 2.5s.7 1.8 1.3 2.3c.3.3.6.6.7.8.1.3.1.8.2 1.2 0 .8.1 1.9.8 2.7.7.8 1.7.8 2.5.8.4 0 .8 0 1.1.1.3.1.6.4 1 .8.6.6 1.4 1.3 2.5 1.3h.1c1 0 1.7-.7 2.3-1.3.3-.3.6-.6.8-.7.3-.1.8-.1 1.3-.2.8 0 1.9-.1 2.7-.8.8-.7.8-1.7.8-2.5 0-.4 0-.8.1-1 .1-.3.4-.6.8-1 .6-.6 1.3-1.4 1.3-2.5-.3-1-1-1.7-1.6-2.3Zm-1.5 3.4c-.4.4-.9.9-1.2 1.6-.3.6-.2 1.3-.2 1.8 0 .4 0 .9-.1 1.1-.2.2-.8.2-1.4.3-.6 0-1.3 0-2 .3-.6.3-1.1.7-1.5 1.1-.2.4-.6.7-.8.7-.3 0-.7-.4-1.2-.8-.4-.4-.9-.9-1.6-1.2-.5-.2-1.1-.2-1.6-.2h-.3c-.4 0-.9 0-1.1-.1-.2-.2-.2-.8-.3-1.4 0-.6 0-1.3-.3-2-.3-.6-.7-1.1-1.1-1.5-.2-.2-.5-.6-.5-.8 0-.3.4-.7.8-1.2.4-.4.9-.9 1.2-1.6.2-.6.2-1.2.2-1.8 0-.4 0-.9.1-1.1.2-.2.8-.2 1.4-.3.6 0 1.3 0 2-.3.6-.3 1.1-.7 1.5-1.1.2-.3.6-.6.8-.6.3 0 .7.4 1.2.8.4.4.9.9 1.6 1.2.6.3 1.3.2 1.8.2.4 0 .9 0 1.1.1.2.2.2.8.3 1.4 0 .6 0 1.3.3 2 .3.6.7 1.1 1.1 1.5.3.3.7.6.7.8-.1.3-.5.7-.9 1.1Z'
    }
  ]
}
