

















import { TelephoneSocial } from '~/constants/telephone/social'
import { v4 as uuidv4 } from 'uuid'
import { defineComponent, PropType, vue3Model } from '~/utils/nuxt3-migration'
import { ciViber } from '~/icons/source/brand/viber'
import { ciWhatsApp } from '~/icons/source/brand/whats-app'

export default defineComponent({
  model: vue3Model,
  props: {
    type: {
      type: String as PropType<TelephoneSocial>,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      id: null
    }
  },
  computed: {
    icons() {
      return {
        viber: ciViber,
        whatsapp: ciWhatsApp
      }
    },
    icon() {
      if (this.type === TelephoneSocial.VIBER) {
        return this.icons.viber
      } else if (this.type === TelephoneSocial.WHATSAPP) {
        return this.icons.whatsapp
      }

      return null
    },
    title() {
      if (this.type === TelephoneSocial.VIBER) {
        return 'Viber'
      } else if (this.type === TelephoneSocial.WHATSAPP) {
        return 'WhatsApp'
      }

      return null
    },
    checked: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  mounted() {
    this.id = uuidv4()
  }
})
