































import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'
import GoogleMapsService from '~/services/GoogleMapsService'
import { useDeps } from '~/compositions/dependency-container'
import PostcodeService from '~/services/location/PostcodeService'
import { PostcodeSuggestion } from '~/models/location/postcode'
import { InputSize } from '~/models/app/input'
import CInputAutocomplete from '~/components/shared/configurable/form/input/CInputAutocomplete.vue'
import { AutocompleteSuggestion } from '~/models/autocomplete'
import { useRequestError } from '~/compositions/request-error'
import { useSnackbar } from '~/compositions/snackbar'
import { ciLocate } from '~/icons/source/solid/locate'

export default defineComponent({
  model: vue3Model,
  components: {
    CInputAutocomplete
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String as PropType<InputSize>,
      default: 'md'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    noAutocomplete: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    selectFirstOnClose: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const snackbar = useSnackbar()
    const [postcodeService, googleMapsService] = useDeps(
      PostcodeService,
      GoogleMapsService
    )
    const handleRequestError = useRequestError()

    const value = ref(props.modelValue)
    const postcodes = ref<PostcodeSuggestion[]>([])
    const suggestions = ref<AutocompleteSuggestion[]>([])
    const geolocationLoading = ref(false)

    const postcode = computed(() => value.value && value.value.name)

    watch(
      () => props.modelValue,
      newValue => {
        value.value = newValue
      }
    )

    async function findFromGeolocation() {
      geolocationLoading.value = true

      try {
        const geolocation = await googleMapsService.getCurrentGpsGeolocation()
        const { postcode } = await googleMapsService.getGeocodingProperties(
          geolocation
        )

        value.value = {
          name: postcode
        }

        emit('update:modelValue', value.value)
        emit('change', value.value)
      } catch (error) {
        if (error.response) {
          handleRequestError(error)
          return
        }

        snackbar.error(error.message)
      } finally {
        geolocationLoading.value = false
      }
    }

    function onInput(input: string) {
      value.value = {
        ...value.value,
        id: null,
        name: input,
        subtext: null
      }

      emit('update:modelValue', value.value)
    }

    function onSuggestionSelect(suggestion: AutocompleteSuggestion) {
      const selectedPostcode = postcodes.value.find(
        postcode => postcode.name === suggestion.id
      )

      if (selectedPostcode) {
        value.value = selectedPostcode

        emit('update:modelValue', value.value)
        emit('change', value.value)
      }

      postcodes.value = []
      suggestions.value = []
    }

    async function onSuggestionsRequest() {
      if (props.noAutocomplete) {
        return
      }

      try {
        postcodes.value = await postcodeService.searchPostcode(postcode.value)

        suggestions.value = postcodes.value.map(p => ({
          id: p.name,
          name: p.name,
          subText: p.subtext
        }))
      } catch (err) {}
    }

    return {
      ciLocate,
      postcodes,
      suggestions,
      geolocationLoading,
      postcode,
      findFromGeolocation,
      onInput,
      onSuggestionsRequest,
      onSuggestionSelect
    }
  }
})
