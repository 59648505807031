var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-rounded-md",class:{
    '!tw-bg-white tw-shadow-md tw-max-w-[420px]': _vm.floating,
    'border tw-bg-white tw-overflow-hidden': _vm.displayInPage
  }},[_c(_vm.notificationTo || _vm.notificationHref ? _vm.CLink : 'div',{tag:"component",staticClass:"tw-block tw-relative list-item tw-w-full",class:{
      'tw-p-1 !tw-bg-white tw-rounded-l-md': _vm.floating,
      'tw-rounded-md': !_vm.floating,
      'tw-cursor-pointer': !(_vm.notificationTo || _vm.notificationHref)
    },attrs:{"href":_vm.notificationHref,"to":_vm.notificationTo,"title":_vm.notification.title},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(!_vm.notification.isRead)?_c('div',{staticClass:"tw-rounded-full tw-absolute not-read",class:{ 'pulse left': _vm.floating }}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-items-center tw-p-2",class:{
        content: _vm.displayInPage
      }},[_c('CAvatar',{attrs:{"width":_vm.displayInPage ? 70 : 60,"height":_vm.displayInPage ? 70 : 60,"rounded":"","avatar":_vm.notification.image,"border":"","alt":_vm.notification.title,"zoomable":false}}),_vm._v(" "),_c('div',{staticClass:"tw-ml-3 tw-overflow-hidden tw-text-lg tw-text-grey-800 tw-flex tw-flex-col",class:{
          'tw-font-medium tw-gap-1': _vm.displayInPage,
          'tw-font-semibold': !_vm.displayInPage
        }},[_c('div',{staticClass:"tw-truncate"},[_vm._v("\n          "+_vm._s(_vm.notification.title)+"\n        ")]),_vm._v(" "),(
            _vm.NotificationTypeEnum.CLASSIFIED_PRICE_REDUCTION ===
              _vm.notification.type
          )?_c('div',{staticClass:"tw-text-base tw-font-normal"},[_c('div',{staticClass:"tw-text-green-500 tw-font-semibold"},[_vm._v("\n            "+_vm._s(_vm.$t('classified price reduction'))+"\n            "+_vm._s(_vm.priceReduction(_vm.notification))+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"tw-font-semibold tw-text-base"},[_vm._v("\n            "+_vm._s(_vm.newPrice(_vm.notification))+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"tw-text-grey-800"},[_vm._v("\n            "+_vm._s(_vm.$t('from').toLowerCase())+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"tw-text-grey-800"},[_vm._v("\n            "+_vm._s(_vm.oldPrice(_vm.notification))+"\n          ")])]):_c('div',{staticClass:"tw-text-base tw-font-normal [word-break:break-word] tw-line-clamp-3"},[_vm._v("\n          "+_vm._s(_vm.notification.message)+"\n        ")])])],1)]),_vm._v(" "),(_vm.floating)?_c('button',{staticClass:"tw-flex tw-justify-center tw-items-center tw-px-2 tw-py-1 tw-rounded-r-md tw-bg-grey-50 tw-text-grey-600",attrs:{"title":_vm.$t('close')},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('CIcon',{attrs:{"icon":_vm.ciTimes}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }