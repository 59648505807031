




































































































































































































































































































































































































































































import { capitalizeAllWords } from '~/utils/string'
import { mapDeps } from '~/plugins/dependency-container/utils'
import AccountService from '~/services/account/AccountService'
import { SET_ACCOUNT_LOADING } from '~/store/modules/shared/account/mutation-types'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { ACCOUNT_NS } from '~/store/modules/shared/account/state'
import {
  GuestSettingsForm,
  GuestSettingsSchema,
  GuestSettingsSchemaResult
} from '~/models/account'
import CPanelCard from '~/components/shared/configurable/cpanel/CPanelCard.vue'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import CInternationalTelephoneInput from '~/components/shared/configurable/form/input/CInternationalTelephoneInput.vue'
import { toCamel, toCamelCase } from '~/utils/object'
import AccountSpinner from '~/components/shared/account/AccountSpinner.vue'
import FormService from '~/services/form/FormService'
import { FullLocation } from '~/models/google'
import CInternationalPostcodeInput from '~/components/shared/configurable/form/input/CInternationalPostcodeInput.vue'
import CConfirmationModal from '~/components/shared/configurable/modal/CConfirmationModal.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import { SellerVerification } from '~/models/classified/types'
import UserVerification from '~/components/car/user/UserVerification.vue'
import ScrollService from '~/services/scroll/ScrollService'
import CInfoModal from '~/components/shared/configurable/info/CInfoModal.vue'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { HttpStatus } from '~/constants/http'
import { defineComponent } from '~/utils/nuxt3-migration'
import { ciPencil } from '~/icons/source/solid/pencil'
import { FormFieldErrors } from '~/models/form/types'
import { defineComponentTranslations } from '~/utils/i18n'
import { ciVerified } from '~/icons/source/regular/verified'

interface Data {
  schema: GuestSettingsSchema | null
  form: GuestSettingsForm
  loadedForm: GuestSettingsForm
  errors: FormFieldErrors
  showDeleteConfirm: boolean
  verification: SellerVerification | null
  showAccountVerificationAlert: boolean
  verifyFetched: boolean
}
export default defineComponent({
  components: {
    CInfoModal,
    UserVerification,
    CConfirmationModal,
    CInternationalPostcodeInput,
    AccountSpinner,
    CInternationalTelephoneInput,
    CFormError,
    CPanelCard
  },
  props: {
    detailsOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    saveText: {
      type: String,
      default: ''
    }
  },
  data(): Data {
    return {
      schema: null,
      form: {} as GuestSettingsForm,
      loadedForm: {} as GuestSettingsForm,
      errors: {} as FormFieldErrors,
      showDeleteConfirm: false,
      verification: null,
      showAccountVerificationAlert: false,
      verifyFetched: false
    }
  },
  computed: {
    ...mapDeps({
      accountService: AccountService,
      formService: FormService,
      scrollService: ScrollService
    }),
    ...mapGetters(ACCOUNT_NS, {
      loading: 'getLoading'
    }),
    ...mapState(USER_NS, {
      email: 'username'
    }),
    icons() {
      return {
        verified: ciVerified,
        pencil: ciPencil
      }
    },
    userIsVerified() {
      return (
        this.verification !== null &&
        this.verification.creditCardVerified &&
        this.verification.telephoneVerified
      )
    },
    userCanBeVerified() {
      return this.verification !== null && this.verification.canBeVerified
    },
    onlyPhoneVerified() {
      return (
        this.verification?.telephoneVerified &&
        !this.verification?.creditCardVerified
      )
    },
    fromActivate() {
      return Boolean(this.$route.query['from-activate'])
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },
  async mounted() {
    window.addEventListener('beforeunload', this.onBeforeUnload)

    await this.fetchForm()
  },
  methods: {
    ...mapMutations(ACCOUNT_NS, {
      setLoading: SET_ACCOUNT_LOADING
    }),
    async fetchForm() {
      this.setLoading(true)
      try {
        const {
          schema,
          values,
          verification
        }: GuestSettingsSchemaResult = await this.accountService.getGuestSettingsSchema()
        this.schema = schema.reduce((acc, obj) => {
          acc[toCamel(obj.name)] = obj
          return acc
        }, {} as GuestSettingsSchema)
        this.setFormData(values)
        if (verification) {
          this.verification = verification
        }
      } catch (error) {
        this.$logger.captureError(error as any)
      } finally {
        this.setLoading(false)
      }
    },
    onBeforeUnload(e: BeforeUnloadEvent) {
      if (this.formChanged()) {
        const confirmationMessage =
          'Are you sure you want to leave the page? Any changes will not be saved'
        e.returnValue = confirmationMessage // This is needed for some browsers.
        return confirmationMessage // This is needed for some other browsers.
      }
    },
    formChanged(): Boolean {
      return Boolean(
        JSON.stringify(this.form) !== JSON.stringify(this.loadedForm) &&
          !this.loading &&
          this.schema
      )
    },
    restrictCheckbox(e: Event) {
      if (!this.userIsVerified) {
        e.preventDefault()
        e.stopPropagation()
        this.showAccountVerificationAlert = true
      }
    },
    async onSubmit() {
      this.errors = {} as FormFieldErrors
      try {
        this.setLoading(true)

        // we need to check this in order to refetch the form and display verification components
        const firstTimeSettingTelephone1 =
          this.loadedForm.telephone1 === null && this.form.telephone1

        const { values } = await this.accountService.submitGuestSettings({
          ...this.form,
          telephone2: this.form.telephone2?.telephone
            ? this.form.telephone2
            : null
        })

        this.setFormData(values)

        if (firstTimeSettingTelephone1) {
          await this.$nextTick()
          await this.fetchForm()
        }

        this.$snackbar.success(this.$t('account details saved successfully'))
        this.$store.dispatch(`${USER_NS}/loadUser`, {}, { root: true })

        if (this.$refs.verification) {
          ;(this.$refs.verification as InstanceType<
            typeof UserVerification
          >).fetchVerificationData()
        }
      } catch (error) {
        if ((error as any).response?.data?.data?.errors) {
          this.errors = toCamelCase((error as any).response.data.data.errors)

          this.formService.onErrorScroll({ errorClass: '.c-form-error' })
        } else if ((error as any).response?.status === HttpStatus.FORBIDDEN) {
          const blacklistReason = (error as any).response?.data?.data?.reason
          if (blacklistReason) {
            window.location.href = `/b/${blacklistReason}/`
          }
        } else {
          this.$snackbar.error(
            this.$t('an error occurred please try again later')
          )
        }
        this.$logger.captureError(error as any)
      } finally {
        this.setLoading(false)
      }
    },
    setFormData(values: GuestSettingsForm) {
      if (values.address === 'No address') {
        values.address = null
      }

      this.form = values
      this.loadedForm = { ...values }
    },
    onLocationChange(data: FullLocation) {
      this.form.geolocation = data.geolocation
      this.form.address = data.address
      this.form.postcode = data.postcode
    },
    async deleteAccount() {
      try {
        this.setLoading(true)
        await this.accountService.deleteGuestAccount()
        this.$snackbar.success(this.$t('your account was deleted successfully'))
        // must logout from car1 for now
        const legacyUrlService = this.$dep(LegacyUrlService)
        window.location.href = legacyUrlService.getUserLogoutUrl()
      } catch (error) {
        this.$logger.captureError(error as any)
        this.setLoading(false)
      }
    },
    getOrder(field: string) {
      switch (field) {
        case 'alias':
          return 'tw-order-3'
        case 'first_name':
          return 'tw-order-1'
        case 'last_name':
          return 'tw-order-2'
        case 'telephone_1':
          return 'tw-order-5'
        case 'telephone_2':
          return 'tw-order-6'
        case 'postcode':
          return 'tw-order-4'
        default:
          return ''
      }
    },
    scrollToVerification() {
      const verifyRef = this.$refs.verify
      if (verifyRef) {
        this.scrollService.scrollTo(verifyRef.$el, {
          duration: 300,
          easing: 'ease-out',
          offset: -100
        })
      }
    },
    onPhoneVerified() {
      if (this.verification) {
        this.verification.telephoneVerified = true
      }
    },
    onCardVerified() {
      if (this.verification) {
        this.verification.creditCardVerified = true
      }
    }
  },
  head() {
    return {
      title: capitalizeAllWords(this.$t('account details').toString())
    }
  },
  i18n: defineComponentTranslations({
    pay_with_credits: {
      en: 'Paying promotions or one click with credits',
      el: 'Πληρωμή προωθήσεων ή one click με χρήση credits'
    },
    uncheck_to_pay_with_euro: {
      en:
        'Uncheck it if you want the payment to be made using euros instead of credits',
      el:
        'Απενεργοποίησέ το αν θέλεις η πληρωμή να γίνεται με χρήση ευρώ αντί για credits'
    },
    now_you_appear_as_verified: {
      en:
        'Now you appear as a verified user to the other members of Car.gr and you have access to extra features',
      el:
        'Πλέον εμφανίζεσαι ως επιβεβαιωμένος χρήστης στα υπόλοιπα μέλη του Car.gr και έχεις πρόσβαση σε επιπλέον λειτουργίες'
    },
    gain_the_trust_of_members: {
      en:
        'Gain the trust of Car.gr members and enjoy more features by verifying your account',
      el:
        'Κέρδισε την εμπιστοσύνη των μελών του Car.gr και απόλαυσε περισσότερες λειτουργίες επιβεβαιώνοντας τον'
    }
  })
})
