




































































import { defineComponent, nextTick, ref } from '~/utils/nuxt3-migration'
import { useDeps } from '~/compositions/dependency-container'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'
import { defineComponentTranslations } from '~/utils/i18n'
import EurobankPaymentsService from '~/services/EurobankPaymentsService'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    paymentType: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    confirmUrl: {
      type: String,
      required: true
    },
    cancelUrl: {
      type: String,
      required: true
    },
    isAmountVisibleInButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const [eurobankPaymentsService] = useDeps(EurobankPaymentsService)
    const snackbar = useSnackbar()
    const { t } = useI18n()

    const submitted = ref(false)
    const paymentForm = ref(null)
    const loading = ref(false)
    const eurobankFormRef = ref()

    async function onButtonClick() {
      loading.value = true

      try {
        const { payment } = await eurobankPaymentsService.createPayment({
          amount: props.amount,
          confirm_url: props.confirmUrl,
          cancel_url: props.cancelUrl,
          type: props.paymentType,
          description: props.description
        })

        paymentForm.value = payment

        await nextTick()
        eurobankFormRef.value.submit()
        submitted.value = true
      } catch (err) {
        snackbar.error(t('something_went_wrong_please_try_again_later'))
      } finally {
        loading.value = false
      }
    }

    return {
      submitted,
      onButtonClick,
      paymentForm,
      eurobankFormRef,
      loading
    }
  },
  i18n: defineComponentTranslations({
    something_went_wrong_please_try_again_later: {
      en: 'Something went wrong, please try again later',
      el: 'Κάτι πήγε στραβά, παρακαλώ δοκίμασε αργότερα'
    }
  })
})
