import { CIcon } from '~/icons/types'
export const ciClassifiedTransfer: CIcon = {
  name: 'classified-transfer',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M364.2 196.4v-70.2H214c-25.2 0-45.7 20.5-45.7 45.7v23.2c0 8.3-6.7 15-15 15s-15-6.7-15-15V172c0-41.7 34-75.7 75.7-75.7h129.7V60.7C343.7 27.2 316.5 0 283 0H86.5C53 0 25.8 27.2 25.8 60.7V325c0 33.5 27.2 60.7 60.7 60.7h51.8v-32c0-8.3 6.7-15 15-15s15 6.7 15 15v97.5c0 33.5 27.2 60.7 60.7 60.7h196.5c33.5 0 60.7-27.2 60.7-60.7v-203H416c-28.6.1-51.8-23.2-51.8-51.8zm-238.7 91.8c-7.4 0-13.4-2.3-17-9.1-4.8-9.2.2-20.6 10.3-23.1 2.9-.7 6.1-.8 9.1-.8h138.9c.4-.6.9-1.3 1.3-1.9-6.3-5.9-12.7-11.7-18.8-17.7-6.3-6.2-12.6-12.5-18.7-18.9-7.5-7.8-7.8-17.8-.8-24.5 6.8-6.7 16.5-6.3 24.1 1.3 21.9 21.9 43.7 43.9 65.4 66 7.9 8 7.9 17.3 0 25.3-21.6 21.7-43.3 43.4-65 65-5 5-10.8 7.2-17.7 4.9-6.2-2-9.9-6.5-11.1-12.8-1.2-6.3 1.4-11.2 5.8-15.6 11.1-11.1 22.2-22.1 33.2-33.2 1.2-1.2 2.3-2.4 4.5-4.8h-7.1c-45.4-.1-90.9-.1-136.4-.1z',
      fill: 'currentColor'
    },
    {
      d:
        'M386.2 126.3v70.2c0 3.6.6 7.1 1.8 10.3.7 1.8 1.5 3.6 2.5 5.2.2.4.5.8.8 1.2.5.8 1.1 1.6 1.7 2.3 1.2 1.5 2.6 2.8 4 4 4 3.3 9 5.6 14.4 6.5 1.5.2 3 .3 4.5.3h70.2l-99.9-100z',
      fill: 'currentColor'
    }
  ]
}
