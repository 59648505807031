












































import { mapGetters, mapState } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  computed: {
    ...mapGetters(USER_NS, {
      usernameIsEmail: 'usernameIsEmail'
    }),
    ...mapState(USER_NS, {
      username: 'username'
    }),
    ...mapDeps({ legacyUrlService: LegacyUrlService })
  }
})
