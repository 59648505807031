









import {
  computed,
  defineComponent,
  onMounted,
  useRoute,
  useRouter
} from '~/utils/nuxt3-migration'
import { useDeps } from '~/compositions/dependency-container'
import GoogleLoginService from '~/services/login/GoogleLoginService'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { useLogger } from '~/compositions/logger'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { defineComponentTranslations } from '~/utils/i18n'
import { toCamelCase } from '~/utils/object'
import useRuntimeConfig from '~/compositions/useRuntimeConfig'
import { useCookies } from '~/compositions/useCookies'

export default defineComponent({
  setup() {
    const [googleLoginService, legacyUrlService] = useDeps(
      GoogleLoginService,
      LegacyUrlService
    )
    const logger = useLogger()
    const snackbar = useSnackbar()
    const { t } = useI18n()
    const route = useRoute()
    const cookies = useCookies()
    const router = useRouter()
    const {
      public: { googleOauthClientId, domain }
    } = useRuntimeConfig()

    const { dispatch: userDispatch } = useNamespacedStore<UserState>(USER_NS)

    const loginUrl = computed(() => {
      return `https://${domain}${legacyUrlService.getUserLoginUrl()}`
    })

    onMounted(() => {
      googleLoginService.showOneTap()
      window.handleOneTapCredentialResponse = async (response: any) => {
        try {
          const { redirectUrl } = await googleLoginService.backendLogin(
            undefined,
            response.credential
          )

          cookies.clearSessionCookies()
          if (
            redirectUrl &&
            ['__login', '__guest_register'].includes(route.value.name)
          ) {
            window.location = redirectUrl
          } else {
            await userDispatch('loadUserSubscribable')
          }
        } catch (error) {
          if (error.response?.data?.data) {
            const { relatedToDealers } = toCamelCase(
              (error as any).response.data.data
            )
            if (relatedToDealers?.length && process.client) {
              window.location.href = router.resolve({
                name: '__login',
                query: {
                  gotonext: route.value.path,
                  username:
                    relatedToDealers.length === 1
                      ? relatedToDealers[0].username
                      : undefined,
                  relatedDealer: 1
                }
              }).href
              return
            }
          }

          logger.captureError(error)
          snackbar.error(t('an error occurred please try again later'))
        }
      }
    })

    return { googleOauthClientId, legacyUrlService, loginUrl }
  },
  i18n: defineComponentTranslations({
    successful_login: {
      en: 'Successful login!',
      el: 'Επιτυχής σύνδεση!'
    }
  })
})
