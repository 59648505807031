
































import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'
import CPostcodeAutocomplete from '~/components/shared/configurable/form/autocomplete/CPostcodeAutocomplete.vue'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import CInput from '~/components/shared/configurable/form/input/CInput.vue'
import { Option, Postcode } from '~/models/shared/types'
import { CountryId } from '~/models/location/country'
import { PostcodeSuggestion } from '~/models/location/postcode'

export default defineComponent({
  model: vue3Model,
  components: {
    CPostcodeAutocomplete,
    CCustomSelect,
    CInput
  },
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: 'postcode'
    },
    customClass: {
      type: String,
      default: ''
    },
    defaultCountry: {
      type: Number,
      default: 1
    },
    modelValue: {
      type: Object as PropType<Postcode>,
      default: null
    },
    noPortal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    },
    countries: {
      type: Array,
      default: () => {
        return [
          {
            code: 'gr',
            name: 'Ελλάδα',
            parent: null,
            has_postcodes: true,
            postcode_validator: '^\\d{5}$',
            telephone_validator: '^(800|801|2[0-9]{2}|69[0-9])[0-9]{7}$',
            id: 1,
            telephone_code: 30
          },
          {
            code: 'cy',
            name: 'Κύπρος',
            parent: null,
            has_postcodes: true,
            postcode_validator: '^\\d{4}$',
            telephone_validator: '^[0-9]*$',
            id: 17,
            telephone_code: 357
          }
        ]
      }
    }
  },
  setup(props, { emit }) {
    const value = ref(props.modelValue)

    const countryOptions = computed((): Option[] => {
      return props.countries.map((country: any) => {
        return {
          text: `${country.code.toUpperCase()} - ${country.name}`,
          headerText: `${country.code.toUpperCase()}`,
          value: country.id,
          key: `${country.id}-${country.name}`
        }
      })
    })
    const countryId = computed(
      () => value.value?.country?.id || props.defaultCountry
    )
    const countryIsForeign = computed(() => {
      return ![CountryId.GREECE, CountryId.CYPRUS].includes(
        countryId.value as number
      )
    })

    watch(
      () => props.modelValue,
      () => {
        value.value = props.modelValue
      }
    )
    watch(
      () => value.value.country.id,
      () => {
        value.value.postcode.id = null
        emit('update:modelValue', value.value)
      }
    )
    watch(
      () => value.value.postcode,
      () => emit('update:modelValue', value.value)
    )

    function onPostcodeChange(selectedPostcodeSuggestion: PostcodeSuggestion) {
      const selectedPostcodeSuggestionCountry = props.countries.find(
        country => country.code === selectedPostcodeSuggestion.countryCode
      )

      if (selectedPostcodeSuggestionCountry?.id !== countryId.value) {
        value.value.country.id = selectedPostcodeSuggestionCountry?.id
      }
    }

    return {
      value,
      countryOptions,
      countryIsForeign,
      onPostcodeChange
    }
  }
})
