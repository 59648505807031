





























































































import {
  computed,
  defineComponent,
  PropType,
  toRefs,
  useRouter
} from '~/utils/nuxt3-migration'
import {
  NotificationEvent,
  NotificationTypeEnum
} from '~/models/notification-center/types'
import { formatNumber } from '~/utils/number'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import { ciTimes } from '~/icons/source/solid/times'

export default defineComponent({
  computed: {
    CLink() {
      return CLink
    }
  },
  components: { CAvatar },
  props: {
    notification: {
      type: Object as PropType<NotificationEvent>,
      required: true
    },
    displayInPage: { type: Boolean, default: false },
    floating: { type: Boolean, default: false }
  },
  setup(props) {
    const { notification } = toRefs(props)
    const router = useRouter()
    const newPrice = () => {
      if (!notification.value.data) {
        return ''
      }
      return `${formatNumber(notification.value.data.newPrice)}€`
    }
    const oldPrice = () => {
      if (!notification.value.data) {
        return ''
      }
      return `${formatNumber(notification.value.data.oldPrice)}€`
    }

    const priceReduction = () => {
      if (!notification.value.data) {
        return ''
      }

      const oldPrice = parseFloat(notification.value.data.oldPrice)
      const newPrice = parseFloat(notification.value.data.newPrice)

      if (!oldPrice || !newPrice) {
        return ''
      }

      const difference = oldPrice - newPrice
      const percentageChange = (difference / oldPrice) * 100

      return `${Math.abs(Math.trunc(percentageChange))}%`
    }

    const hasNuxtRoute = computed(() => {
      const resolvedRoute = router.resolve({
        path: notification.value.targetUrl
      })
      return Boolean(resolvedRoute.route?.name)
    })

    const pathToUse = computed(() => {
      return (
        notification.value.targetUrl || `/${notification.value.classifiedId[0]}`
      )
    })
    const notificationHref = computed(() => {
      if (!notification.value.targetUrl) {
        return undefined
      }

      return hasNuxtRoute.value || pathToUse.value.startsWith('http')
        ? pathToUse.value
        : null
    })

    const notificationTo = computed(() => {
      if (!notification.value.targetUrl) {
        return undefined
      }

      return hasNuxtRoute.value || pathToUse.value.startsWith('http')
        ? null
        : {
            path: pathToUse.value
          }
    })

    return {
      NotificationTypeEnum,
      newPrice,
      oldPrice,
      priceReduction,
      notificationHref,
      notificationTo,
      ciTimes
    }
  }
})
