import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { Classified } from '~/models/classified/types'
import { Result } from '~/models/shared/result'
import { StringMap } from '~/models/shared/types'
import { formatPage } from '~/services/formatters'
import { invalidBodyError } from './errors'

@containerScoped()
export default class RogueClassifiedService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async transferFromSingleClassifiedUser(
    classifiedId: string
  ): Promise<object> {
    const { data: body } = await this.http.post(
      `/api/guests/rogue-classifieds/${classifiedId}/transfer/`
    )
    return {
      error: body.error,
      message: body.message
    }
  }

  async getRogueClassifiedsMap(
    page: number = 1
  ): Promise<Result<StringMap<Classified>>> {
    const { data: body } = await this.http.get(
      '/api/guests/rogue-classifieds/',
      { params: { pg: page } }
    )

    const { rows, pagination } = body.data.classifieds
    if (!body || !body.data || !body.data || !Array.isArray(rows)) {
      throw invalidBodyError(body)
    }

    return {
      data: new Map(
        rows.map((clsfd: any) => [clsfd.id && clsfd.id.toString(), clsfd])
      ),
      page: pagination && formatPage(pagination)
    }
  }
}
