









































































































import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import ListRow from '~/components/car/classifieds/search/results/rows/main/ListRow.vue'
import { Classified } from '~/models/classified/types'
import { StringMap } from '~/models/shared/types'
import RogueClassifiedService from '~/services/RogueClassifiedService'
import { ciClassifiedTransfer } from '~/icons/source/solid/classified-transfer'
import { CIcon } from '~/icons/types'
import { USER_NS } from '~/store/modules/shared/user/state'
import { TRANSFER_ROGUE_CLASSIFIED } from '~/store/modules/shared/user/mutation-types'
import GalleryRow from '~/components/car/classifieds/search/results/rows/main/GalleryRow.vue'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { defineComponent } from '~/utils/nuxt3-migration'
import { ciCheck } from '~/icons/source/solid/check'
import { ciTimes } from '~/icons/source/solid/times'

interface Data {
  classifieds?: StringMap<Classified>
  transferredClassifiedMessages: Map<string, string>
  transferredClassifiedErrors: Map<string, string>
  loading: boolean
}

export default defineComponent({
  components: {
    GalleryRow,
    ListRow
  },
  data(): Data {
    return {
      classifieds: undefined,
      transferredClassifiedMessages: new Map(),
      transferredClassifiedErrors: new Map(),
      loading: false
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    icons(): Record<string, IconDefinition | CIcon> {
      return {
        ciCheck,
        ciTimes,
        ciClassifiedTransfer
      }
    }
  },
  async mounted() {
    await this.loadRogueClassifieds()
  },
  methods: {
    async loadRogueClassifieds() {
      const rogueClassifiedService = this.$dep(RogueClassifiedService)
      this.loading = true
      try {
        this.classifieds = (
          await rogueClassifiedService.getRogueClassifiedsMap()
        ).data
      } catch (error) {
        this.$logger.captureError(error as string)
      } finally {
        this.loading = false
      }
    },
    async transferClassified(id: string) {
      const rogueClassifiedService = this.$dep(RogueClassifiedService)

      try {
        const {
          error,
          message
        } = await rogueClassifiedService.transferFromSingleClassifiedUser(id)
        if (error) {
          const transferred = new Map(this.transferredClassifiedErrors)
          transferred.set(id, error)
          this.transferredClassifiedErrors = transferred
        } else {
          const transferred = new Map(this.transferredClassifiedMessages)
          transferred.set(id, message)
          this.transferredClassifiedMessages = transferred
        }

        this.$store.commit(`${USER_NS}/${TRANSFER_ROGUE_CLASSIFIED}`)
      } catch (error) {
        const transferred = new Map(this.transferredClassifiedErrors)
        transferred.set(id, {
          error: error.response?.data?.error,
          status: error.response?.data?.status
        })
        this.transferredClassifiedErrors = transferred
        this.$logger.captureError(`${error}`)
      }
    }
  }
})
