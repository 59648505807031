var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"verify-phone"},[_c('div',{staticClass:"vrf-header"},[_vm._v("\n      "+_vm._s(_vm.inPanel ? _vm.$t('step {step}', { step: '#1' }) : _vm.$t('phone confirmation'))+"\n    ")]),_vm._v(" "),_c('CCollapse',{attrs:{"model-value":""}},[_c('div',{staticClass:"tw-relative tw-bg-white tw-w-full tw-h-full tw-min-h-72 tw-p-4 verify-phone-wrapper"},[(_vm.message.msg)?_c('CAlert',{attrs:{"variant":_vm.message.variant,"dismissible":_vm.message.variant === 'success'}},[_vm._v("\n          "+_vm._s(_vm.message.msg)+"\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.verificationPhoneState !==
              _vm.verificationStateEnum.ALREADY_VERIFIED &&
              _vm.verificationPhoneState !== _vm.verificationStateEnum.VERIFIED
          )?_c('h1',{staticClass:"phone-title"},[_vm._v("\n          "+_vm._s(_vm.$t('phone verification'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-between phone-info-container"},[_c('div',{class:{ 'tw-w-full': _vm.hasUserVerifiedPhone }},[(_vm.hasUserVerifiedPhone)?_c('div',{staticClass:"already-verified"},[_c('SuccessCheckmarkAnimation',{attrs:{"is-mounted":"","lg":""}}),_vm._v(" "),_c('p',[_vm._v("\n                "+_vm._s(_vm.verificationPhoneState === _vm.verificationStateEnum.VERIFIED
                    ? _vm.$t('the phone verification completed successfully')
                    : _vm.$t('your phone number has already been verifying'))+"\n              ")]),_vm._v(" "),(!_vm.inPanel)?_c('CButton',{attrs:{"disabled":_vm.isButtonsDisabled,"size":"sm","href":_vm.accountSettingsUrl,"variant":"primary"}},[_vm._v(_vm._s(_vm.$t('settings'))+"\n              ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(
                !_vm.hasUserVerifiedPhone &&
                  _vm.verificationPhoneState !== _vm.verificationStateEnum.ENTER_PIN
              )?_c('p',{staticClass:"info-text"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'press the "{buttonname}" button and we will send you an sms with a 5-digit code to confirm that this phone number belongs to you.',
                  { buttonname: _vm.$t('send') }
                ))+"\n            ")]):_vm._e(),_vm._v(" "),(
                _vm.verificationPhoneState === _vm.verificationStateEnum.ENTER_PIN
              )?_c('p',{staticClass:"info-text"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  "type the 5-digit code that was sent to your mobile phone through sms and press the 'send' button"
                ))+"\n            ")]):_vm._e(),_vm._v(" "),(
                _vm.verificationPhoneState !==
                  _vm.verificationStateEnum.ALREADY_VERIFIED &&
                  _vm.verificationPhoneState !== _vm.verificationStateEnum.VERIFIED
              )?_c('div',{staticClass:"form-container"},[_c('CForm',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('label',{staticClass:"tw-mb-2 tw-block",attrs:{"for":"phone-code-input"}},[_vm._v(_vm._s(_vm.verificationPhoneState === _vm.verificationStateEnum.ENTER_PIN
                    ? _vm.$t('please enter pin')
                    : _vm.$t('phone number')))]),_vm._v(" "),_c('div',{staticClass:"input-container"},[_c('CInputGroup',{attrs:{"has-error":_vm.inputVerificationPhoneState === false,"disabled":_vm.verificationPhoneState ===
                        _vm.verificationStateEnum.NOT_VERIFIED},scopedSlots:_vm._u([(
                        _vm.verificationPhoneState ===
                          _vm.verificationStateEnum.NOT_VERIFIED
                      )?{key:"prepend",fn:function(){return [_c('span',{staticClass:"code"},[_vm._v("+"+_vm._s(_vm.code))])]},proxy:true}:null],null,true)},[_vm._v(" "),_c('CInput',{attrs:{"id":"phone-code-input","readonly":_vm.verificationPhoneState ===
                          _vm.verificationStateEnum.NOT_VERIFIED,"maxlength":"5"},model:{value:(_vm.phoneOrPin),callback:function ($$v) {_vm.phoneOrPin=$$v},expression:"phoneOrPin"}})],1)],1),_vm._v(" "),(_vm.hasUserVerifiedPhone)?_c(_vm.inPanel ? 'CLink' : 'div',{tag:"component",staticClass:"tw-text-sm tw-cursor-pointer",attrs:{"href":_vm.inPanel ? null : _vm.accountSettingsUrl},on:{"click":_vm.handleChangePhone}},[_vm._v("\n                  "+_vm._s(_vm.$t('change phone number'))+"\n                ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"submit-btn-container"},[_c('CButton',{attrs:{"disabled":_vm.isLoading || _vm.isButtonsDisabled || _vm.isInputEmpty,"size":"sm","type":"submit","variant":"primary"}},[_vm._v("\n                    "+_vm._s(_vm.buttonLabel)+"\n                  ")])],1)],1)],1):_vm._e()]),_vm._v(" "),(!_vm.hasUserVerifiedPhone)?_c('div',{staticClass:"verify-phone-img"},[_c('img',{attrs:{"src":require("assets/img/user/phone-verify.svg"),"alt":"phone"}})]):_vm._e()])],1)])],1),_vm._v(" "),_c('div',{staticClass:"verify-card",class:{
      disabled: !_vm.hasUserVerifiedPhone || _vm.isPaymentLoading
    }},[_c('div',{staticClass:"vrf-header"},[_vm._v("\n      "+_vm._s(_vm.inPanel
          ? _vm.$t('step {step}', { step: '#2' })
          : _vm.$t('credit card confirmation'))+"\n    ")]),_vm._v(" "),_c('CCollapse',{staticClass:"panel",attrs:{"model-value":""}},[(!_vm.hasUserVerifiedCard)?[(!_vm.hasUserVerifiedPhone)?_c('CAlert',{staticClass:"tw-mb-2",attrs:{"variant":"warning"}},[_vm._v("\n          "+_vm._s(_vm.$t('you need to verify your telephone first'))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.cardMessage.msg)?_c('CAlert',{staticClass:"tw-mb-4",attrs:{"variant":_vm.cardMessage.variant,"content-class":{
            'tw-flex tw-items-center tw-justify-center tw-flex': _vm.hasUserVerifiedPhone
          }}},[_vm._v("\n          "+_vm._s(_vm.cardMessage.msg)+"\n        ")]):_vm._e(),_vm._v(" "),_c('CPayButton',{staticClass:"tw-flex tw-justify-end",attrs:{"label":_vm.$t('credit card confirmation'),"amount":1,"description":"credits","payment-type":"guest_verification","confirm-url":_vm.accountSettingsUrl,"cancel-url":_vm.cancelUrl,"disabled":!_vm.hasUserVerifiedPhone}}),_vm._v(" "),(_vm.cardMessage.msg && _vm.hasUserVerifiedPhone)?_c('CAlert',{staticClass:"tw-mt-4",attrs:{"variant":_vm.cardMessage.variant,"content-class":"tw-flex tw-items-center tw-justify-center tw-flex"}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'alternatively you can verify your card by purchasing any amount of credits that you can use to promote your classified'
            ))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.hasUserVerifiedPhone)?_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('CButton',{staticClass:"tw-flex tw-items-center",attrs:{"variant":"primary","size":"sm","href":_vm.$router.resolve({
                name: '__account_credits'
              }).href}},[_vm._v("\n            "+_vm._s(_vm.$t('verify by credits purchase'))+"\n          ")])],1):_vm._e()]:_c('div',[(_vm.hasUserVerifiedCard)?_c('div',{staticClass:"already-verified"},[_c('SuccessCheckmarkAnimation',{attrs:{"is-mounted":"","lg":""}}),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.verificationCardState === _vm.verificationStateEnum.VERIFIED
                ? _vm.$t('the card verification completed successfully')
                : _vm.$t('your card has already been verifying'))+"\n          ")])],1):_vm._e()])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }